<template>
	<div class="InvoiceApply">
		<!-- 发票类型 start -->
		<div class="item flex align-center">
			<span class="item-name">发票类型</span>
			<div class="item-div">
				<el-radio-group v-model="paper">
					<el-radio :label="1">纸质发票</el-radio>
					<!-- <el-radio :label="0">电子发票</el-radio> -->
				</el-radio-group>
			</div>
		</div>
		<!-- 发票类型 end -->
		<!-- <div class="item flex" style="padding-top: 0">
			<span class="item-name">发票信息</span>
			<div class="item-div"></div>
		</div> -->

		<!-- 抬头类型 start -->
		<div class="item flex align-center" style="padding-top: 0">
			<span class="item-name">抬头类型</span>
			<div class="item-div">
				<el-radio-group v-model="invoice_type">
					<el-radio :label="1">个人</el-radio>
					<el-radio :label="2">企业</el-radio>
				</el-radio-group>
			</div>
		</div>
		<!-- 抬头类型 end -->

		<!-- 发票抬头 start -->
		<div class="item flex">
			<span class="item-name"><span>*</span>发票抬头</span>
			<div class="item-div">
				<el-input
					v-model="invoice_title"
					maxlength="30"
					placeholder="发票抬头不能为空"
					clearable
				/>
			</div>
		</div>
		<!-- 发票抬头 end -->

		<!-- 税号 start -->
		<div v-if="invoice_type == 2" class="item flex">
			<span class="item-name"><span>*</span>税号</span>
			<div class="item-div">
				<el-input
					v-model="invoice_company_duty_paragraph"
					placeholder="税号不能为空"
					clearable
				/>
			</div>
		</div>
		<!-- 税号 end -->

		<!-- 发票内容 start -->
		<div class="item flex">
			<span class="item-name">发票内容</span>
			<div class="item-div">
				<el-input v-model="content" placeholder="填写备注（非必填)" clearable />
			</div>
		</div>
		<!-- 发票内容 end -->

		<!-- 姓名 start -->
		<div class="item flex">
			<span class="item-name"><span>*</span>姓名</span>
			<div class="item-div">
				<el-input v-model="name" placeholder="姓名不能为空" clearable />
			</div>
		</div>
		<!-- 姓名 end -->

		<!-- 联系方式 start -->
		<div class="item flex">
			<span class="item-name"><span>*</span>联系方式</span>
			<div class="item-div">
				<el-input
					v-model="invoice_mobile"
					maxlength="11"
					placeholder="联系方式不能为空"
					clearable
				/>
			</div>
		</div>
		<!-- 联系方式 end -->

		<!-- 电子邮箱 start -->
		<div class="item flex">
			<span class="item-name"><span>*</span>电子邮箱</span>
			<div class="item-div">
				<el-input
					v-model="invoice_email"
					placeholder="电子邮箱不能为空"
					clearable
				/>
			</div>
		</div>
		<!-- 电子邮箱 end -->

		<!-- 地址 start -->
		<div class="item flex">
			<span class="item-name"><span>*</span>地址</span>
			<div class="item-div">
				<el-cascader
					style="width: 100%"
					placeholder="请选择地址"
					:options="cityArrs"
					:props="{
						value: 'n',
						label: 'n',
						children: 'd',
					}"
					v-model="address"
					filterable
				/>
			</div>
		</div>
		<!-- 地址 end -->

		<!-- 详细地址 start -->
		<div class="item flex">
			<span class="item-name"></span>
			<div class="item-div">
				<el-input
					style="width: 100%; height: 140px"
					v-model="address_detail"
					type="textarea"
					placeholder="请填写详细地址：如道路、门牌号、小区、楼栋号等…"
					show-word-limit
					maxlength="100"
					minlength="10"
					:resize="'none'"
					clearable
				></el-input>
			</div>
		</div>
		<!-- 详细地址 end -->

		<div class="btn flex justify-center">
			<el-button :loading="loading" @click="btn">提交</el-button>
		</div>
	</div>
</template>
<script>
export default {
	name: "InvoiceApply",
	props: {
		log_id: {
			required: true,
		},
		invoice_price: {
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			cityArrs: [], // 地址的数据

			paper: 1, // 是否为纸质 0否 1是 	Integer[整数]	必填
			invoice_type: 1, // 个人或公司类型（1个人 2公司） 	   Integer[整数]	必填
			invoice_title: "", // 发票抬头	String[字符串]	必填
			invoice_company_duty_paragraph: "", // 税号 （invoice_type==2时必填） 	String[字符串]	选填
			content: "", // 发票内容（备注）	String[字符串]	选填
			name: "", // 姓名	String[字符串]	必填
			invoice_mobile: "", // 手机号	String[字符串]	选填
			invoice_email: "", // 邮箱	String[字符串]	选填
			// invoice_price: "", // invoice_price开票金额 	Float[浮点数]	必填	0
			address: "", // 地址	String[字符串]	必填
			address_detail: "", // 详细地址 	String[字符串]	必填
		};
	},
	created() {
		this.$pageScrollTop();

		let arr = this.$localGetItem("threeCity") || [];

		this.cityArrs = this.$replaceChildrenEmpty(arr, {
			d: "d",
		});
	},
	methods: {
		// 提交发票
		btn() {
			let {
				log_id,
				paper, // 是否为纸质 0否 1是 	Integer[整数]	必填
				invoice_type, // 个人或公司类型（1个人 2公司） 	   Integer[整数]	必填
				invoice_title, // 发票抬头	String[字符串]	必填
				invoice_company_duty_paragraph, // 税号 （invoice_type==2时必填） 	String[字符串]	选填
				content, // 发票内容（备注）	String[字符串]	选填
				name, // 姓名	String[字符串]	必填
				invoice_mobile, // 手机号	String[字符串]	选填
				invoice_email, // 邮箱	String[字符串]	选填
				invoice_price, // invoice_price开票金额 	Float[浮点数]	必填	0
				address, // 地址	String[字符串]	必填
				address_detail, // 详细地址 	String[字符串]	必填
			} = this;

			if (!invoice_title || !invoice_title.toString().trim()) {
				this.$message.info("请填写发票抬头");
				return false;
			}

			if (
				invoice_type == 2 &&
				(!invoice_company_duty_paragraph ||
					!invoice_company_duty_paragraph.toString().trim())
			) {
				this.$message.info("请填写税号");
				return false;
			}

			if (!name || !name.toString().trim()) {
				this.$message.info("请填写姓名");
				return false;
			}

			if (!invoice_mobile || !invoice_mobile.toString().trim()) {
				this.$message.info("请填写手机号");
				return false;
			}

			if (!this.$validate(invoice_mobile, "phone")) {
				this.$message.info("请填写正确的手机号");
				return false;
			}

			if (!invoice_email || !invoice_email.toString().trim()) {
				this.$message.info("请填写邮箱");
				return false;
			}

			if (!this.$validate(invoice_email, "email")) {
				this.$message.info("请填写正确的邮箱");
				return false;
			}

			if (!address || !address.toString().trim()) {
				this.$message.info("请选择地址");
				return false;
			}

			if (!address_detail || !address_detail.toString().trim()) {
				this.$message.info("请填写详细地址");
				return false;
			}

			this.loading = true;

			this.$API
				.applyInvoice({
					log_id,
					paper,
					invoice_type,
					invoice_title,
					invoice_company_duty_paragraph,
					content,
					name,
					invoice_mobile,
					invoice_email,
					invoice_price,
					address,
					address_detail,
				})
				.then((res) => {
					this.loading = false;
					console.log("ress", res);
					if (res.code == 1) {
						this.$message.success("申请开票");
						this.$emit("operate", {});
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					this.loading = false;
					console.log("err", err);
				});
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.el-textarea {
		textarea {
			height: 100%;
		}
	}

	.el-input__count {
		background-color: transparent;
	}
}

.InvoiceApply {
	@extend .w100;
	@extend .h100;

	.item {
		padding-top: 32px;
		line-height: 40px;

		.item-name {
			width: 120px;
			text-align: right;
			padding-right: 24px;
			font-size: 16px;
			font-weight: 400;
			color: #333333;

			span {
				color: #f03c3b;
			}
		}

		.item-div {
			width: 480px;

			.el-textarea {
				textarea {
					height: 100%;
				}
			}
		}
	}

	.btn {
		margin-top: 40px;

		button {
			border: none;
			padding: 0;
			text-align: center;
			width: 240px;
			height: 48px;
			line-height: 48px;
			background: #ff8300;
			border-radius: 24px;
			font-size: 16px;
			color: #ffffff;
		}
	}
}
</style>
