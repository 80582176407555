<template>
	<div class="Invoice bgfff br4 p32">
		<div v-if="curType == 'list'">
			<div class="nav fs16 fw-7 font-33 pb24 pr00 flex justify-between">
				<div class="tit-line flex">
					<span class="pr">我的发票</span>
					<span class="line">我的发票</span>
				</div>
				<span @click="topFun" class="right fw-4 fs14">开发票</span>
			</div>
			<InvoiceList @operate="operate"></InvoiceList>
		</div>

		<!-- 单独的详情列表 start -->
		<div v-if="curType == 'detailList'" class="detailList">
			<!-- 面包屑 start -->
			<div
				class="bread flex align-center font-33 pb24"
				style="border-bottom: 1px dashed #eeeeee"
			>
				您现在的位置：<el-breadcrumb
					separator="/"
					separator-class="el-icon-arrow-right"
				>
					<el-breadcrumb-item
						@click.stop.prevent.native="
							(curType = 'list'), (scrollTop = 0), (curDetailId = '')
						"
						class="is-link"
						>电子发票</el-breadcrumb-item
					>
					<el-breadcrumb-item>开票订单</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- 面包屑 end -->

			<InvoiceDetailList :id="curDetailId"></InvoiceDetailList>
		</div>
		<!-- 单独的详情列表 end -->

		<!-- 开发票列表 start -->
		<div v-if="curType == 'openList' || curType == 'apply'" class="openList">
			<!-- 面包屑 start -->
			<div
				class="bread flex align-center font-33 pb24"
				style="border-bottom: 1px dashed #eeeeee"
			>
				您现在的位置：<el-breadcrumb
					separator="/"
					separator-class="el-icon-arrow-right"
				>
					<el-breadcrumb-item
						@click.stop.prevent.native="(scrollTop = 0), (curType = 'list')"
						class="is-link"
						>电子发票</el-breadcrumb-item
					>
					<el-breadcrumb-item
						@click.stop.prevent.native="(curType = 'openList'), scrollView()"
						:class="{ 'is-link': curType == 'apply' }"
						>开发票</el-breadcrumb-item
					>
					<el-breadcrumb-item v-if="curType == 'apply'"
						>开发票资料填写</el-breadcrumb-item
					>
				</el-breadcrumb>
			</div>
			<!-- 面包屑 end -->

			<div v-if="curType == 'openList'">
				<div style="min-height: 600px" v-loading="listLoading">
					<div v-if="arrs.length > 0 || listLoading">
						<div
							v-for="(item, index) in arrs"
							:key="index"
							class="list-item bgfff pb32 pt32 flex cursor"
							@click="checkedFun(item, index)"
						>
							<div class="img-wrap flex" :style="{ width: '44px' }">
								<img
									class="checked-img"
									:src="
										item.checked
											? require('@/assets/public/checked.png')
											: require('@/assets/public/unchecked.png')
									"
								/>
							</div>
							<div style="flex: 1">
								<div class="tit flex justify-between fs14 font-33 fw-7">
									<div style="flex: 1" class="fs16">{{ item.level_name }}</div>
									<span class="">￥{{ item.level_price }}</span>
								</div>
								<div class="fs14 font-66 mt12">
									订单编号：{{ item.order_sn }}
								</div>
								<div class="fs14 font-66 mt12">
									支付方式：{{ item.pay_type }}
								</div>
								<div class="fs14 font-66 mt12">
									购买时间：{{ item.pay_time }}
								</div>
							</div>
						</div>
					</div>
					<div
						v-else
						class="mt24 bgfff br4"
						style="overflow: hidden; padding-bottom: 60px"
					>
						<Empty></Empty>
					</div>
				</div>
				<!-- 分页 start -->
				<div class="page-wrap flex justify-between mt32">
					<div class="left fs16 font-33 flex align-center">
						<span>{{ checkedArrs.length }}</span
						>个订单，共 <span>{{ allPrice }}</span
						>元
					</div>
					<el-pagination
						:background="true"
						layout="prev, pager, next"
						:total="total"
						:current-page="page"
						:page-size="pageSize"
						@current-change="change"
						hide-on-single-page
					>
					</el-pagination>
				</div>
				<!-- 分页 end -->

				<!-- 底部 start -->
				<div class="footer flex align-center bgfff mt24">
					<div class="left flex">
						<div
							class="checked flex align-center fs16 font-66 cursor"
							@click="checkedAllFun"
						>
							<img
								class="mr12"
								:src="
									checkedAll
										? require('@/assets/public/checked.png')
										: require('@/assets/public/unchecked.png')
								"
							/>
							全选
						</div>
					</div>

					<div class="right">
						<button @click="next" class="flex align-center justify-center">
							下一步
						</button>
					</div>
				</div>
				<!-- 底部 end -->
			</div>

			<InvoiceApply
				v-if="curType == 'apply'"
				:log_id="log_id"
				:invoice_price="allPrice"
				@operate="applyOperate"
			></InvoiceApply>
		</div>
		<!-- 开发票列表 end -->
	</div>
</template>
<script>
import InvoiceList from "@/components/InvoiceList";
import InvoiceDetailList from "@/components/InvoiceDetailList";
import InvoiceApply from "@/components/InvoiceApply";
export default {
	name: "Invoice",
	components: {
		InvoiceList,
		InvoiceDetailList,
		InvoiceApply,
	},
	data() {
		return {
			// 当前在那里  list 列表; detailList 单独的详情列表;
			// 						openList 开发票列表; apply 开票;
			curType: "list",
			curDetailId: "", // 跳转详情 id

			arrs: [],
			total: 0, // 总条数
			pageSize: 15, // 每页条数
			page: 1, // 当前的页码
			listLoading: false,
			checkedAll: false, // 是否全选

			scorllTop: 0, // 距离顶部得距离  从发票跳转至列表需要回到指定位置
			log_id: "", // 开发票跳转的时候 需要开的 id 列表
		};
	},
	mounted() {
		window.addEventListener("scroll", this.invoiceScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.invoiceScroll);
	},
	computed: {
		// 选中的 数组列表
		checkedArrs() {
			let { arrs } = this;
			let ar = arrs.filter((item) => {
				return item.checked;
			});
			return ar;
		},
		// 总价
		allPrice() {
			let { checkedArrs } = this;
			let num = 0;
			checkedArrs.forEach((item) => {
				let price = item.level_price || 0;
				price = Number(price) * 10000;
				num = num + price;
			});

			return num / 10000;
		},
	},
	methods: {
		// 回到固定位置
		scrollView() {
			document.documentElement.scrollTop = this.scorllTop;
			document.body.scrollTop = this.scorllTop;
		},
		// 监听滚动
		invoiceScroll() {
			if (this.curType == "openList") {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				this.scorllTop = scrollTop;
			}
		},
		// 开票了 需要切换
		applyOperate() {
			this.scrollTop = 0;
			this.curType = "list";
			this.isLastPage();
			this.getList();
		},

		// 顶部点击到开发票
		topFun() {
			this.scrollTop = 0;
			this.curType = "openList";
			this.page = 1;
			this.getList();
		},
		// 列表的操作
		operate(item) {
			this.curType = "detailList";
			this.curDetailId = item.id;
		},

		next() {
			if (this.checkedArrs.length == 0) {
				this.$message.info("请选择想要开票的订单");
				return false;
			}

			this.log_id = this.checkedArrs
				.map((item) => {
					return item.id;
				})
				.join(",");

			this.curType = "apply";
		},

		// 是否是最后一页
		isLastPage() {
			let { page, total, pageSize } = this;
			if (page == Math.ceil(total / pageSize)) {
				this.page = this.page - 1;
			}
		},
		/**
		 * ! 每个选项的选中点击切换
		 */
		checkedFun(item, index) {
			if (item.checked) {
				this.arrs[index].checked = false;
			} else {
				this.arrs[index].checked = true;
			}

			let checkedAll = true;
			for (let i = 0; i < this.arrs.length; i++) {
				let it = this.arrs[i];
				if (!it.checked) {
					checkedAll = false;
					break;
				}
			}

			this.checkedAll = checkedAll;
		},
		/**
		 * ! 全选的点击
		 */
		checkedAllFun() {
			if (this.checkedAll) {
				this.arrs = this.arrs.map((item) => {
					return {
						...item,
						checked: false,
					};
				});
			} else {
				this.arrs = this.arrs.map((item) => {
					return {
						...item,
						checked: true,
					};
				});
			}

			this.checkedAll = !this.checkedAll;
		},
		// 分页改变时
		change(e) {
			this.page = e;
			this.getList();
		},

		// 数据列表
		getList() {
			if (this.listLoading) {
				return false;
			}

			let { page, pageSize } = this;

			this.listLoading = true;
			this.checkedAll = false;
			this.$pageScrollTop();

			this.$API
				.applyList({
					page,
					pagesize: pageSize,
					list_rows: pageSize,
					pageSize,
				})
				.then((res) => {
					this.listLoading = false;

					if (res.code == 1) {
						this.total = res.data.total;
						this.arrs = res.data.data.map((item) => {
							return {
								...item,
								checked: false,
							};
						});
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.bread {
		.is-link {
			span {
				font-weight: 700;
				text-decoration: none;
				transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
				color: #303133;
				cursor: pointer;

				&:hover {
					color: #409eff;
				}
			}
		}
	}

	.invoice-table {
		th {
			background-color: #f8f8f8;
			border: none;
			color: #333333;
			text-align: center;
		}

		td {
			text-align: center;
			font-size: 14px;
			color: #333333;
		}
	}
}

.Invoice {
	@extend .w100;
	@extend .h100;

	.nav {
		border-bottom: 1px dashed #eeeeee;

		.right {
			cursor: pointer;
			color: #4177dd;
		}
	}

	.tit {
		border-bottom: 1px dashed #eeeeee;

		.right {
			cursor: pointer;
			color: #4177dd;
		}
	}

	.list-item {
		border-bottom: 1px solid #eeeeee;

		.tit {
			align-items: flex-start;
			border-bottom: none;

			span {
				font-size: 16px;
				font-weight: 500;
				color: #f03c3b;
			}
		}

		.right {
			height: 100%;
			font-size: 32px;
			font-family: Lato;
			font-weight: 500;
			color: #f03c3b;
		}
	}

	.img-wrap {
		.checked-img {
			width: 20px;
			height: 20px;
		}
	}

	.page-wrap {
		span {
			color: #f03c3b;
		}
	}

	.footer {
		width: 100%;
		border-top: 1px solid #eeeeee;
		padding: 12px 0;

		.left {
			flex: 1;

			.checked {
				img {
					width: 20px;
					height: 20px;
				}
			}
		}

		.right {
			button {
				width: 120px;
				height: 40px;
				background: #ff8300;
				border-radius: 44px;
				font-size: 14px;
				color: #ffffff;
			}
		}
	}
}
</style>
