<template>
	<div class="InvoiceList">
		<div class="flex justify-between align-cneter pt24">
			<div @click="dateChange(null)" class="left fs14 fw-7 font-33 cursor">
				全部
			</div>
			<div class="right">
				<el-date-picker
					@change="dateChange"
					v-model="dateValue"
					type="daterange"
					range-separator="至"
					start-placeholder="开始时间"
					end-placeholder="结束时间"
					value-format="yyyy-MM-dd"
					:editable="false"
					:disabled="listLoading"
				>
				</el-date-picker>
			</div>
		</div>
		<div style="min-height: 600px" v-loading="listLoading" class="mt12">
			<div v-if="arrs.length > 0 || listLoading">
				<el-table :data="arrs" border style="width: 100%" class="invoice-table">
					<el-table-column prop="order_sn" label="订单号" width="180">
					</el-table-column>
					<el-table-column prop="invoice_add_time" label="下单时间" width="190">
					</el-table-column>
					<el-table-column
						prop="invoice_title"
						label="发票抬头"
					></el-table-column>
					<el-table-column prop="invoice_status_txt" label="发票状态">
					</el-table-column>
					<el-table-column label="开票金额">
						<template slot-scope="scope">
							￥{{ scope.row.invoice_price }}
						</template>
					</el-table-column>
					<el-table-column label="操作"
						><template slot-scope="scope">
							<el-button
								@click="handleDetail(scope.row)"
								type="text"
								size="small"
								>查看</el-button
							>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div
				v-else
				class="mt24 bgfff br4"
				style="overflow: hidden; padding-bottom: 60px"
			>
				<Empty></Empty>
			</div>
		</div>
		<!-- 分页 start -->
		<div class="page-wrap flex justify-end mt32">
			<el-pagination
				:background="true"
				layout="prev, pager, next"
				:total="total"
				:current-page="page"
				:page-size="pageSize"
				@current-change="change"
				hide-on-single-page
			>
			</el-pagination>
		</div>
		<!-- 分页 end -->
	</div>
</template>
<script>
export default {
	name: "InvoiceList",
	data() {
		return {
			arrs: [],
			total: 0, // 总条数
			pageSize: 15, // 每页条数
			page: 1, // 当前的页码
			listLoading: false,
			dateValue: "", // 时间插件的值
			startDate: "",
			endDate: "",
		};
	},
	created() {
		this.getList();
	},
	methods: {
		handleDetail(item) {
			this.$emit("operate", item);
		},
		// 时间切换
		dateChange(e) {
			if (e) {
				this.startDate = e[0];
				this.endDate = e[1];
			} else {
				this.startDate = "";
				this.endDate = "";
			}

			this.change(1);
		},
		// 分页改变时
		change(e) {
			this.page = e;
			this.getList();
		},
		// 数据列表
		getList() {
			if (this.listLoading) {
				return false;
			}

			let { page, pageSize, startDate, endDate } = this;

			this.listLoading = true;
			this.$pageScrollTop();

			this.$API
				.applyHistoryList({
					page,
					pagesize: pageSize,
					list_rows: pageSize,
					pageSize,
					start_date: startDate,
					end_date: endDate,
				})
				.then((res) => {
					this.listLoading = false;

					if (res.code == 1) {
						this.total = res.data.total;
						this.arrs = res.data.data;
					} else {
						this.$message.error(res.msg);
					}

					console.log("this,arrs", this.arrs);
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
	},
};
</script>
<style scoped lang="scss">
.InvoiceList {
	@extend .w100;
	@extend .h100;
}
</style>
